<template>
    <div v-if="labelLowerCase == 'shift'">
        <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9L4 16H12V9L15 9V7L8 0L1 7L1 9H4ZM3.82843 7L6 7L6 14H10L10 7L12.1716 7L8 2.82843L3.82843 7Z" fill="#000000"/>
        </svg>
    </div>

    <div v-else-if="labelLowerCase == 'caps'">
        <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 16 16" fill="none" style="transform: rotate(180deg);">    
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9L4 16H12V9L15 9V7L8 0L1 7L1 9H4ZM3.82843 7L6 7L6 14H10L10 7L12.1716 7L8 2.82843L3.82843 7Z" fill="#000000"/>
        </svg>
    </div>

    <div v-else-if="labelLowerCase == 'tab'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 24 24">
        <path d="M22 4.25a.75.75 0 00-1.5 0v15a.75.75 0 001.5 0v-15zm-9.72 14.28a.75.75 0 11-1.06-1.06l4.97-4.97H1.75a.75.75 0 010-1.5h14.44l-4.97-4.97a.75.75 0 011.06-1.06l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25z"/></svg>
    </div>

    <div v-else-if="labelLowerCase == 'backspace'">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="800px" height="800px" viewBox="0 0 16 16" version="1.1">
            <path fill="#444" d="M5 12l-5-4 5-4v2h11v4h-11v2z"/>
        </svg>
    </div>

    <div v-else>
        {{ label }}
    </div>
</template>

<script>
export default {
    name: "KeyboardIconsComponent",
    props: {
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        labelLowerCase() {
            return this.label.toLowerCase();
        },
    }
}

</script>

<style scoped>
svg {
    width: 2rem;
    height: 2rem;
}
svg path {
    fill: #fff;
}
</style>