<template>
  <div>
    <header-component :msg="$t('register.title')"></header-component>
    <div class="content">
      <div class="partner_categories">
        <div
          v-for="(category, index) in partner_categories"
          :key="index"
          class="category"
          :class="{ active: category.selected }"
          @click="setCategory(category)"
        >
          <div class="category-title">
            {{ $t(`register.categories.${category.name}`) }}
          </div>
        </div>
      </div>
      <div class="partners" ref="partners">
        <div
          class="partner"
          v-for="(partner, index) in visiblePartners"
          :key="index"
          @click="showTerms(partner)"
          :class="{
            'partner-selected': isPartnerSelected(partner.id),
          }"
        >
          <div class="partner-deal" v-if="partner.deal">
            {{ partner.deal }}
          </div>
          <div
            class="partner-terms"
            v-if="partner.showTerms"
            v-click-away="() => (partner.showTerms = false)"
          >
            <div class="partner-links">
              <u @click.stop="openLink(partner.tos)">{{ $t("register.terms.agb") }}</u>
              {{ $t("register.terms.and") }}
              <u @click.stop="openLink(partner.privacypolicy)">{{
                $t("register.terms.dsgvo")
              }}</u>
            </div>
            <div class="partner-accept-button" @click.stop="selectPartner(partner)">
              {{ $t("register.terms.accept") }}
            </div>
          </div>
          <div class="partner-box">
            <img :src="partner.image" :alt="partner.name" />
          </div>
        </div>
      </div>
      <div class="overflow-dots" v-if="totalDots > 1">
        <span
          v-for="(n, index) in totalDots"
          :key="n"
          class="dot"
          :style="{ backgroundColor: index === activeDot ? '#68AEF9' : '#D9D9D9' }"
          @click="scrollToPartner(index)"
        ></span>
      </div>
      <div class="btn" @click="submit()">Abschließen</div>
    </div>
    <language-component></language-component>

    <iframe-component v-if="link" :link="link" @close="hideIframe"></iframe-component>
  </div>
</template>

<script>
import LanguageComponent from "../components/LanguageComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import IframeComponent from "../components/IframeComponent.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { LanguageComponent, HeaderComponent, IframeComponent },

  name: "RegisterPage",
  data() {
    return {
      currentIndex: 0,
      link: "",
    };
  },
  computed: {
    ...mapGetters(["isPartnerSelected", "getSelectedPartners", "partner_categories"]),
    visiblePartners() {
      const partners = this.$store.getters.partners;

      const selectedCategories = this.partner_categories.filter(
        (category) => category.selected
      );

      if (selectedCategories[0]?.name === "All") {
        return partners;
      }

      const selectedPartners = partners.filter((partner) => {
        return selectedCategories.some((category) => category.name === partner.category);
      });

      return selectedPartners;
    },
    totalDots() {
      return Math.ceil(this.visiblePartners.length / 8);
    },
    activeDot() {
      return this.currentIndex;
    },
  },
  methods: {
    ...mapMutations(["TOGGLE_SELECTED_PARTNER"]),
    setCategory(category) {
      this.partner_categories.forEach((category) => {
        category.selected = false;
      });

      const partners = this.$store.getters.partners;
      partners.forEach((p) => {
        p.showTerms = false;
      });

      category.selected = true;
    },
    hideIframe() {
      this.link = "";
    },
    showTerms(partner) {
      if (this.isPartnerSelected(partner.id)) {
        this.TOGGLE_SELECTED_PARTNER(partner);
        return;
      }

      const partners = this.$store.getters.partners;
      partners.forEach((p) => {
        p.showTerms = false;
      });

      partner.showTerms = true;
    },
    selectPartner(partner) {
      this.TOGGLE_SELECTED_PARTNER(partner);

      setTimeout(() => {
        partner.showTerms = false;
      }, 0);
    },
    openLink(link) {
      // pre add https://
      if (!link.startsWith("https")) {
        link = "https://" + link;
      }
      console.log("open", link);
      this.link = link;
    },
    scrollToPartner(index) {
      if (index < 0 || index >= this.totalDots) return;

      this.currentIndex = index;
      const partnersElement = this.$refs.partners;
      const scrollAmount = (partnersElement.scrollWidth / this.totalDots) * index;
      partnersElement.scrollTo({ left: scrollAmount, behavior: "smooth" });
    },
    handleScroll() {
      const partnersElement = this.$refs.partners;
      const totalWidth = partnersElement.scrollWidth - partnersElement.clientWidth;
      const scrollFraction = partnersElement.scrollLeft / totalWidth;

      this.currentIndex = Math.round(scrollFraction * (this.totalDots - 1));
    },
    submit() {
      if (this.getSelectedPartners.length === 0) {
        return;
      }
      this.$router.push("/register-done");
    },
  },
  created() {
    this.$store.dispatch("loadPartners");
  },
  mounted() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
.content {
  width: 64vw;
  height: 100%;
  margin-left: -2vw;
}

.partners {
  overflow-x: auto;
  height: 19vw;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
  flex-direction: column;
  flex-wrap: wrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  margin-top: 1vw;
  align-content: flex-start;
}

.partners > div:nth-child(-n + 2) .partner-terms {
  margin-left: 10vw;
}

.partners > div:nth-last-child(-n + 2) .partner-terms {
  margin-right: 10vw;
}

.partner {
  width: 13vw;
  height: 9vw;
  background: rgba(217, 217, 217, 0.3);
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  min-height: 0;
}

.partner-deal {
  background: #fff;
  font-family: "Roboto Medium";
  position: absolute;
  top: 0.5vw;
  left: 0.5vw;
  padding: 0.5vw;
  font-size: 0.75vw;
  border-radius: 0.5vw;
}

.partner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.partner-box img {
  max-width: 7.5vw;
  max-height: 7.5vw;
  object-fit: contain;
  z-index: -1;
}

.partner-terms {
  position: absolute;
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1vw;
  letter-spacing: -0.05vw;
  padding: 0.75vw 1vw;
  border-radius: 1.5vw;
  width: 20rem;
  height: 5rem;
  text-align: center;
  line-height: 1.5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.partner-accept-button {
  background: #fff;
  border-radius: 3vw;
  font-family: "Roboto Bold";
  font-size: 1vw;
  height: 3rem;
  color: #68aef9;
  letter-spacing: -0.05vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
}

.partner-terms::before {
  content: "";
  position: absolute;
  top: -0.5vw;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.5vw;
  border-style: solid;
  border-color: transparent transparent #68aef9 transparent;
}

.overflow-dots {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

.dot {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  margin: 0 0.25vw;
  box-shadow: 0 5px 5px 0 #44444425;
  cursor: pointer;
}

.partner-selected {
  background: #68aef9;
  z-index: 1;
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  width: 13vw;
  height: 2vw;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 2vw;
  box-shadow: 0 5px 5px 0 rgba(45, 45, 45, 0.15);
  margin: auto;
  margin-top: 2vh;
  transition: transform 0.2s;
}

.btn:hover {
  transform: scale(1.05);
}

.btn:focus {
  outline: none;
}

.partner_categories {
  display: flex;
  gap: 1vw;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category {
  padding: 0.5vw;
  background: #b6b1b1;
  min-width: 9vw;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1vw;
  text-align: center;
  border-radius: 3vw;
}

.category-title {
  height: 1vw;
}

.active {
  background: transparent !important;
  border: 0.2vw solid #fff;
  border-radius: 3vw;
  line-height: 1vw;
}

.category:hover {
  cursor: pointer;
}
</style>
